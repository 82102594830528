<template>
  <Header>
    <div class="driver-header d-none d-md-block">
      <div class="header-driver-transparent-bg">
        <div class="container">
          <div class="row">
            <div class="col-6 pt-lg-5">
              <h1 class="color-white header-text">
                Become the <br />New Taxi Experience<br />
                Every Rider Desire
              </h1>
            </div>
            <div class="col-lg-1 d-none d-lg-block"></div>
            <div class="col-lg-5 col-md-6 pl-5">
              <el-card class="box-card px-3 py-3 header-card">
                <div slot="header" class="clearfix">
                  <h3 class="dark">Apply to Drive</h3>
                </div>
                <div>
                  <b-form ref="form" v-model="valid">
                    <div class="dropoff-pickup-div">
                      <!-- <div class="text-position"><span class="size-lable">Enter first name</span></div> -->
                      <b-form-group class="input-field-group">
                        <b-form-input
                          placeholder="Enter first name"
                          class="card-form"
                          v-model="formValues.driverFirstName"
                          :rules="requiredRules"
                          :disabled="isValidPhoneNumber ? true : false"
                          autocomplete="false"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group class="input-field-group">
                        <!-- <div class="text-position"><span class="size-lable">Enter last name</span></div> -->

                        <b-form-input
                          placeholder="Enter last name"
                          class="card-form"
                          autocomplete="false"
                          :disabled="isValidPhoneNumber ? true : false"
                          v-model="formValues.driverLastName"
                          :rules="requiredRules"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="contact-container">
                      <!-- <div class="text-position"><span class="size-lable">Contact number</span></div> -->
                      <div class="img-num-code">
                        <img src="../assets/imgs/flags/mu.png" alt="image" />
                        <span class="color-light-grey">
                          {{ selectedCountry.dial_code }}
                        </span>
                      </div>
                      <b-form-group class="input-field-group pl-5">
                        <b-form-input
                          type="number"
                          v-model="formValues.driverPhoneNumber"
                          :rules="requiredRules"
                          placeholder="Contact number"
                          :disabled="isValidPhoneNumber ? true : false"
                          class="card-form pl-4"
                        ></b-form-input>
                      </b-form-group>
                      <div v-if="!isValidPhoneNumber">
                        <b-form-group class="pt-4">
                          <el-button
                            type="primary"
                            name="verify-now"
                            class="btn-block bg-primary"
                            @click="verifyPhoneNumber"
                            :loading="loading"
                          >
                            Verify Now
                          </el-button>
                        </b-form-group>

                        <div class="d-flex flex-row-reverse">
                          <a
                            href="https://driver.viteapp.co/"
                            class="font-14 ml-2 color-primary clickable"
                            >Login</a
                          >
                          <span class="font-14">Already have account? </span>
                        </div>
                      </div>
                      <div
                        class="otp-container"
                        v-if="isValidPhoneNumber && !isOtpVerified"
                      >
                        <div class="opt-verify-btn">
                          <el-button
                            type="primary"
                            class="bg-primary"
                            size="mini"
                            name="verify-otp"
                            @click="verifyOtp"
                            :loading="loading"
                          >
                            Verify
                          </el-button>
                        </div>
                        <b-form-group>
                          <b-form-input
                            type="text"
                            v-model="formValues.otp"
                            :rules="requiredRules"
                            placeholder="OTP Number"
                            class="card-form pr-5"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div v-if="isOtpVerified">
                        <b-form-group class="input-field-group">
                          <i class="fas fa-envelope"></i>
                          <b-form-input
                            type="email"
                            placeholder="Enter Email Address"
                            class="card-form"
                            v-model="formValues.driverEmail"
                            :rules="emailRules"
                            autocomplete="false"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-field-group">
                          <i class="fas fa-lock"></i>
                          <b-form-input
                            type="password"
                            placeholder="Enter Password"
                            class="card-form"
                            v-model="formValues.driverPassword"
                            :rules="passwordRules"
                            autocomplete="false"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="pt-4">
                          <el-button
                            type="primary"
                            name="register"
                            class="btn-block bg-primary"
                            @click="createUser"
                            :loading="loading"
                          >
                            Register
                          </el-button>
                        </b-form-group>
                      </div>
                    </div>
                  </b-form>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container d-block d-md-none">
      <div class="driver-header-mobile">
        <div class="header-driver-transparent-bg">
          <div class="container">
            <h2 class="mt-5 px-2 color-white header-mob-text">
              Become the New <br />Taxi Experience Every<br />
              Rider Desire
            </h2>
          </div>
        </div>
      </div>
      <div class="">
        <el-card class="box-card px-3 py-3 header-card">
          <div slot="header" class="clearfix">
            <h3 class="dark">Apply to Drive</h3>
          </div>
          <div>
            <b-form ref="form" v-model="valid">
              <div class="dropoff-pickup-div">
                <b-form-group class="input-field-group">
                  <b-form-input
                    placeholder="Enter First Name"
                    class="card-form"
                    v-model="formValues.driverFirstName"
                    :rules="requiredRules"
                    :disabled="isValidPhoneNumber ? true : false"
                    autocomplete="false"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="input-field-group">
                  <b-form-input
                    placeholder="Enter Last Name"
                    class="card-form"
                    autocomplete="false"
                    :disabled="isValidPhoneNumber ? true : false"
                    v-model="formValues.driverLastName"
                    :rules="requiredRules"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="contact-container">
                <div class="img-num-code">
                  <img src="../assets/imgs/flags/mu.png" alt="image" />
                  <span class="color-light-grey">
                    {{ selectedCountry.dial_code }}
                  </span>
                </div>
                <b-form-group class="input-field-group pl-5">
                  <b-form-input
                    type="number"
                    v-model="formValues.driverPhoneNumber"
                    :rules="requiredRules"
                    placeholder="Contact Number"
                    :disabled="isValidPhoneNumber ? true : false"
                    class="card-form pl-4"
                  ></b-form-input>
                </b-form-group>
                <div v-if="!isValidPhoneNumber">
                  <b-form-group class="pt-4">
                    <el-button
                      type="primary"
                      name="verify-now-register"
                      class="btn-block bg-primary"
                      @click="verifyPhoneNumber"
                      :loading="loading"
                    >
                      Verify Now
                    </el-button>
                  </b-form-group>

                  <div class="d-flex flex-row-reverse">
                    <span class="font-14 ml-2 color-primary clickable"
                      >Login</span
                    >
                    <span class="font-14">Already have account? </span>
                  </div>
                </div>
                <div
                  class="otp-container"
                  v-if="isValidPhoneNumber && !isOtpVerified"
                >
                  <div class="opt-verify-btn">
                    <el-button
                      type="primary"
                      class="bg-primary"
                      size="mini"
                      name="ver"
                      @click="verifyOtp"
                      :loading="loading"
                    >
                      Verify
                    </el-button>
                  </div>
                  <b-form-group>
                    <b-form-input
                      type="text"
                      v-model="formValues.otp"
                      :rules="requiredRules"
                      placeholder="OTP Number"
                      class="card-form pr-5"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="isOtpVerified">
                  <b-form-group class="input-field-group">
                    <i class="fas fa-envelope"></i>
                    <b-form-input
                      type="email"
                      placeholder="Enter Email Address"
                      class="card-form"
                      v-model="formValues.driverEmail"
                      :rules="emailRules"
                      autocomplete="false"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group class="input-field-group">
                    <i class="fas fa-lock"></i>
                    <b-form-input
                      type="password"
                      placeholder="Enter Password"
                      class="card-form"
                      v-model="formValues.driverPassword"
                      :rules="passwordRules"
                      autocomplete="false"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group class="pt-4">
                    <el-button
                      type="primary"
                      class="btn-block bg-primary"
                      @click="createUser"
                      :loading="loading"
                      name="regi"
                    >
                      Register
                    </el-button>
                  </b-form-group>
                </div>
              </div>
            </b-form>
          </div>
        </el-card>
      </div>
    </div>
  </Header>
</template>

<script>
import firebase from "../plugins/firebase";
import "firebase/auth";
import { DriverService } from "../services/driver.service";
import axios from "axios";
export default {
  data() {
    return {
      formValues: {},
      show: false,
      valid: true,
      lazy: true,
      show1: false,
      loading: false,
      requiredRules: [(v) => !!v || "This is required"],
      passwordRules: [
        (v) =>
          !!v ||
          ("This is required" && !!v && v.length < 6) ||
          "Maximum 6 character required for password.",
      ],
      isValidPhoneNumber: false,
      isOtpVerified: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      selectedCountry: {
        name: "Mauritius",
        dial_code: "+230",
        code: "MU",
      },
    };
  },
  methods: {
    goToDriverPage() {
      window.location("https://driver.viteapp.co/");
    },
    verifyPhoneNumber() {
      if (!this.formValues.driverPhoneNumber) {
        this.$message({
          showClose: true,
          message: "Please add Phone Number.",
          type: "warning",
        });
        return;
      }
      this.loading = true;

      let phoneToVerify =
        this.selectedCountry.dial_code + this.formValues.driverPhoneNumber;
      const url =
        "https://us-central1-vite-application.cloudfunctions.net/twilioRider/api/request";
      axios
        .post(url, {
          phone: phoneToVerify,
          client_secret: "8455b720d94d48dfa96953fe3c75c35b",
        })
        .then((response) => {
          console.log(
            "🚀 ~ file: DriverHeader.vue ~ line 370 ~ .then ~ response",
            response
          );
          this.isValidPhoneNumber = true;
          this.$message({
            showClose: true,
            message: "OTP sent to Your Contact Number.",
            type: "success",
          });
        })
        .catch(() => {
          phoneToVerify = "";
          this.$message({
            showClose: true,
            message: "Invalid Phone Number.",
            type: "warning",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verifyOtp() {
      if (!this.formValues.otp) {
        this.$message({
          showClose: true,
          message: "Please enter OTP. Send In your phone.",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      let phoneToVerify =
        this.selectedCountry.dial_code + this.formValues.driverPhoneNumber;
      const url =
        "https://us-central1-vite-application.cloudfunctions.net/twilioRider/api/verify";
      axios
        .post(url, {
          phone: phoneToVerify,
          sms_message: this.formValues.otp,
          client_secret: '8455b720d94d48dfa96953fe3c75c35b',
        })
        .then((response) => {
          if (response.data && !response.data.success) {
            this.$message({
              showClose: true,
              message: "Invalid OTP. Please Enter valid OPT.",
              type: "error",
            });
          } else {
            this.isOtpVerified = true;
            this.$message({
              showClose: true,
              message: "Phone Number Verified Successfully.",
              type: "success",
            });
          }
        })
        .catch(() => {
          phoneToVerify = "";
          this.$message({
            showClose: true,
            message: "Invalid Phone Number.",
            type: "warning",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createUser() {
      if (!this.isOtpVerified) {
        this.$message({
          showClose: true,
          message: "Please verify your phone number.",
          type: "warning",
        });
        return;
      }
      if (
        this.formValues.driverPassword &&
        this.formValues.driverPassword.length < 6
      ) {
        this.$message({
          showClose: true,
          message: "Password required at least 6 character or more.",
          type: "error",
        });
        return;
      }
      if (this.isOtpVerified) {
        let phoneNumber =
          this.selectedCountry.dial_code + this.formValues.driverPhoneNumber;
        firebase
          .auth()
          .createUserWithEmailAndPassword(
            this.formValues.driverEmail,
            this.formValues.driverPassword
          )
          .then((response) => {
            console.log(
              "🚀 ~ file: DriverHeader.vue ~ line 266 ~ .then ~ response",
              response
            );
            DriverService.registerDriver({
              phoneNo: phoneNumber.replace("+", ""),
              email: this.formValues.driverEmail,
              password: this.formValues.driverPassword,
              firstName: this.formValues.driverFirstName,
              lastName: this.formValues.driverLastName,
              gender: "Male",
              avatarUrl: "",
              city: "Mauritius",
              country: "Mauritius",
              idUrl: "",
            })
              .then((response) => {
                console.log(
                  "🚀 ~ file: DriverHeader.vue ~ line 297 ~ .then ~ response",
                  response
                );
                if (response.message == "Driver already exists.") {
                  this.$message({
                    showClose: true,
                    message: "Driver Already Exists!",
                    type: "warning",
                  });
                  return;
                }
                this.$message({
                  showClose: true,
                  message: "Registration Successful. Proceed to Logins.",
                  type: "success",
                });
                phoneNumber = "";
                window.location = "https://driver.viteapp.co";
                localStorage.setItem("authUser", "");
              })
              .catch((error) => {
                console.log(
                  "🚀 ~ file: DriverHeader.vue ~ line 308 ~ .then ~ error",
                  error
                );
                this.$message({
                  showClose: true,
                  message: "Something went wrong.",
                  type: "error",
                });
                phoneNumber = "";
              });
          });
      }
    },
  },
};
</script>
<style scoped>
.driver-header {
  width: 100%;
  height: 670px;
  background-image: url("../assets/imgs/driver/driver-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.driver-header-mobile {
  width: 100%;
  height: 250px;
  margin-top: 69px;
  background-image: url("../assets/imgs/driver/driver-bg.png");

  background-size: 100% 100%;
  background-position: center center;
}
.header-driver-transparent-bg {
  width: 100%;
  height: 100%;
  background-image: url("../assets/imgs/driver/driver-bg-transparent-1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.card-form {
  height: 48px;
  background-color: #f6f6f6;
  border: none;
  border-radius: 0px;
}
.card-form:focus {
  border: none;
  background-color: #f6f6f6;
  box-shadow: none;
}
.card-form::placeholder {
  color: #6e6e6e;
  font-size: 20px;
  font-weight: 500;
}
.header-text {
  padding-top: 250px;
  font-size: 56px;
  font-weight: bold;
}
.header-card {
  margin-top: 50px;
  min-height: 470px;
}
.contact-container {
  position: relative;
}
.contact-container .img-num-code {
  position: absolute;
  background-color: #f6f6f6;
  height: 48px;
  z-index: 101;
  padding-left: 10px;
  padding-top: 12px;
}
.header-mob-text {
  padding-top: 6rem;
}
.otp-container {
  position: relative;
}
.card-form.pr-5 {
  padding-right: 7rem !important;
}
.opt-verify-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.size-lable {
  font-size: 18px;
  font-weight: bold;
  color: #8b37ff;
}
</style>
